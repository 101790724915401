/* font: normal normal bold 30px/50px New York Small !important; */
/* .intro-text {
  font-style: normal !important;
  font-variant: normal !important;
  font-weight: bold !important;
  font-size: 30px !important;
  line-height: 50px !important;
  font-family: New York small !important;
} */



@media (min-width: 840px){
  .intro-text {
    /* font: normal normal bold 40px/60px New York Small !important;
     */
    font-style: bold !important;
    font-variant: normal !important;
    font-weight: bold !important;
    font-size: 40px !important;
    line-height: 60px !important;
    font-family: New York Small !important;
  }
}


@media (max-width: 376px){
  .text-email-small {
    font-size: 13px;
  }
}



