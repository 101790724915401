@tailwind base;
@tailwind components;
@tailwind utilities;


@layer base {


  @font-face{
    font-family:"argentum-sans-light-regular";
    src:url("https://dafonttop.com/wp-data/a/457/9457/file/argentum-sans.light.ttf") format("woff"),
    url("https://dafonttop.com/wp-data/a/457/9457/file/argentum-sans.light.ttf") format("opentype"),
    url("https://dafonttop.com/wp-data/a/457/9457/file/argentum-sans.light.ttf") format("truetype");
}

  @font-face {
    font-family: 'ArgentumRegular';
    src: local('ArgentumRegular'), url(./public/fonts/ArgentumSans-Regular.otf) format('opentype');
  }

  @font-face {
    font-family: 'Argentum Extra Light';
    src: url("./public/fonts/ArgentumSans-ExtraLight.otf") format('otf');

  }

  @font-face {
    font-family: 'ArgentumMedium';
    src: local('ArgentumMedium'), url(./public/fonts/ArgentumSans-Medium.otf) format('opentype');

  }
  @font-face {
    font-family: 'ArgentumLight';
    src: local('ArgentumLight'),url(./public/fonts/ArgentumSans-Light.otf) format('opentype');
  }
  @font-face {
    font-family: 'ArgentumLightItalic';
    src: local('ArgentumLightItalic'),url(./public/fonts/ArgentumSans-LightItalic.otf) format('opentype');

  }
  @font-face {
    font-family: 'ArgentumSemiBold';
    src: local('ArgentumSemiBold'),url(./public/fonts/ArgentumSans-SemiBold.otf) format('opentype');

  }
  @font-face {
    /* src */
    font-family: 'ArgentumBold';
    src: local('ArgentumBold'),url(./public/fonts/ArgentumSans-Bold.otf) format('opentype');
  }

}




.argentum-lt {
  font-family: ArgentumLight;
}


body, html {
  height: 100%;
  margin: 0;
}



#home-page{
  scroll-behavior:smooth !important;

}

/* html { 
  background: url("./public/images/fond/bg.png") no-repeat center center fixed; 
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}   */
html {
  background: url("./public/images/fond/bg-min.png") no-repeat center center fixed; 
  background-position: center center;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  height: 100%; 
  overflow: hidden;
  }
  body {           
    overflow: scroll;
  }

  /* @media screen and (max-width: 500px) {
    html {
      background: url("./public/images/fond/bg.png") no-repeat center center fixed; 
      background-position: center center;
      -webkit-background-size: cover;
      -moz-background-size: cover;
      -o-background-size: cover;
      background-size: cover;
      height: 100%; 
      overflow: hidden;
      }
  } */
  /*  
  */
  /* height:100%; */